export const joyrideStyle = {
  options: {
    primaryColor: "black",
  },
  overlay: { height: "1000vh" },
};

export const joyrideLocale = {
  close: "Schließen",
  last: "Schließen",
  next: "Weiter",
};

export const JOB_ADVERT_CONTROL_SELECTOR = "jobAdvertControl";
export const RESUME_CONTROL_SELECTOR = "resumeControl";
