import React, { useState } from "react";
import { Spinner } from "./spinner";
import placeholderImage from "./media/businessman-icon.svg";
import { NO_PRINT } from "./styles";

const full = {
  width: "100%",
  height: "100%",
};
export function Headshot({
  revisionId,
  style,
  onLoad,
}: {
  revisionId: string | null;
  style: React.CSSProperties;
  onLoad?: (event: React.SyntheticEvent<HTMLImageElement>) => void;
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  if (revisionId == null) {
    return null;
  }

  const placeholderImageElement = (
    <img src={placeholderImage} alt="Headshot placeholder" style={style} />
  );

  let placeholder = null;
  if (isLoading) {
    placeholder = (
      <div className={NO_PRINT} style={full}>
        <Spinner />
        {placeholderImageElement}
      </div>
    );
  } else if (hasError) {
    placeholder = (
      <div className={NO_PRINT} style={full}>
        {placeholderImageElement}
      </div>
    );
  }
  return (
    <div
      style={{
        ...style,
        position: "relative",
      }}
    >
      {(isLoading || hasError) && placeholder}
      <img
        src={`/api/headshot/${revisionId}`}
        // landscape
        // src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQmo5cxe_E60DZhZrSFSbHVIq7goY8P1fRL7g&s"}
        // square
        // src={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRC8aoXHy-QszyMu9jhKuaj4kg99flkdhERyw&s"}
        // portrait
        // src={
        //   "https://assets.pixolum.com/blog/wp-content/uploads/2019/09/Bewerbungsfoto-Bildausschnitt-w%C3%A4hlen-800x1067.jpg"
        // }
        alt=""
        style={{
          ...style,
          display: isLoading ? "none" : "block",
        }}
        onError={(e) => {
          setIsLoading(false);
          setHasError(true);
        }}
        onLoad={(e) => {
          setIsLoading(false);
          if (onLoad) {
            onLoad(e);
          }
        }}
      />
    </div>
  );
}
