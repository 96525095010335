export function Impressum() {
  return (
    <main className="row justify-content-center">
      <div className="col-xl-6">
        <h1>Impressum</h1>

        <p>Diensteanbieter gemäß § 5 TMG:</p>

        <p>
          Gunther Bidlingmaier
          <br />
          Georgenstr. 42
          <br />
          80799 München
          <br />
          <a href="mailto:kontakt@bewerbungshelfer.net">
            kontakt@bewerbungshelfer.net
          </a>
        </p>
      </div>
    </main>
  );
}
