import { useState, useEffect } from "react";
import { MOBILE_WIDTH_THRESHOLD } from "./styles";

export function useDeviceType(): boolean {
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    const checkDeviceType = () => {
      setIsMobile(window.innerWidth <= MOBILE_WIDTH_THRESHOLD);
    };

    // Initial check
    checkDeviceType();

    // Add event listener
    window.addEventListener("resize", checkDeviceType);

    // Clean up event listener
    return () => {
      window.removeEventListener("resize", checkDeviceType);
    };
  }, []);

  return isMobile;
}

export default useDeviceType;
