import { JsonResume } from "../bindings/JsonResume";
import { RevisionId } from "../bindings/RevisionId";

export async function getJsonResume(
  revisionId: RevisionId,
): Promise<JsonResume | null> {
  const response = await fetch(`/api/json-resume/${revisionId.revisionId}`);

  if (!response.ok) {
    throw new Error("Failed to fetch json resume:" + response.statusText);
  }

  return await response.json();
}
