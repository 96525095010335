import React from "react";

export function Overlay() {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgba(0, 0, 0, 0.5)", // Dimming effect
        zIndex: 1050, // Make sure it covers other elements
      }}
    />
  );
}
